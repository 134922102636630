@import "fonts.css";
@import "loader.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sb-theme-color: #151522;
  --sb-main-color: #53b9ab;
  --dark-neutral-gray-12: #edeef0;
  --font-family: "Oswald";
  --teal-dark-teal-11: #0bd8b6;
  --dark-neutral-gray-11: #b0b4ba;
  --teal-dark-teal-12: #adf0dd;
  --spacer-spacer-0: 0;
  --base-bg-color: #1e1e32;
  --grass-grass-8: #65ba75;
  --gray-tone-color: #edeef0;
}

.main {
  background: var(--sb-theme-color);
  color: var(--dark-neutral-gray-12, #edeef0);
  font-family: var(--font-family);
  min-height: 100svh;
}

.bg-main {
  background: var(--sb-theme-color);
}

.auth-title {
  color: var(--dark-neutral-gray-12, #edeef0);
  /* Heading 2 */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
}

.main-title {
  color: var(--dark-neutral-gray-12, #edeef0);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.font-exo {
  font-family: Exo;
}

.btn-primary {
  font-family: Exo;
  border-radius: 4px;
  border: 2px solid #53b9ab;
  background: linear-gradient(180deg, #26aa99 0%, #26aa99 100%);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.main-color {
  color: var(--teal-dark-teal-11);
}

.text-gray-11 {
  color: var(--dark-neutral-gray-11);
}
.text-gray-12 {
  color: var(--dark-neutral-gray-12);
}

.bottom-bar .active {
  position: relative;
  color: var(--teal-dark-teal-12, #adf0dd);
  overflow: hidden;
}

.bottom-bar .active::before {
  position: absolute;
  top: 0;
  content: " ";
  width: 102px;
  height: 6px;
  border-radius: var(--spacer-spacer-0, 0px) var(--spacer-spacer-0, 0px) 120px
    120px;
  background: var(--teal-dark-teal-11, #0bd8b6);
}

.bottom-bar .active::after {
  position: absolute;
  bottom: -50px;
  height: 34px;
  width: 100%;
  box-shadow: 0 0 5px var(--teal-dark-teal-11),
    0 0 25px var(--teal-dark-teal-11), 0 0 50px var(--teal-dark-teal-11),
    0 0 200px var(--teal-dark-teal-11);
  content: " ";
}

.scan-qr {
  filter: drop-shadow(0px 2px 4px rgba(164, 221, 210, 0.34))
    drop-shadow(0px 10px 24px rgba(17, 166, 137, 0.45));
}

table thead tr th,
table tbody tr td {
  color: var(--dark-neutral-gray-11);
}

.my-qr-code svg {
  width: 100%;
}

.history-list li.active {
  border-bottom-color: #0eb39e;
}

.button-custom-shadow {
  box-shadow: 0px 2px 4px 0px rgba(164, 221, 210, 0.34),
    0px 10px 24px 0px rgba(17, 166, 137, 0.45);
}

.button-info-custom-shadow {
  box-shadow: 0px 2px 4px 0px rgba(213, 174, 57, 0.35),
    0px 10px 24px 0px rgba(213, 174, 57, 0.15);
}

.zIndexTop {
  z-index: 9999;
}

.league-content-tab .customTab {
  background: transparent;
  color: #777b84;
}

.league-content-tab .hover\:bg-neutral-100:hover {
  background: transparent;
  border-bottom-color: #0eb39e;
}

.league-content-tab .text-primary {
  color: #edeef0;
}

.border-b-custom {
  border-bottom-color: rgba(94, 94, 94, 0.17);
}

.bg-base {
  background: var(--base-bg-color);
}

.background-accent {
  background: var(--sb-main-color);
}

.text-accent {
  color: var(--sb-main-color);
}

.custom-marker .MuiSlider-markLabel {
  color: #ffffff;
}

.custom-marker .MuiSlider-mark {
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid #b0b4ba;
  background: #edeef0;
}

.custom-marker .MuiSlider-mark[data-index="4"] {
  left: calc(100% - 12px) !important;
}

.custom-marker .MuiSlider-mark.MuiSlider-markActive {
  border: 4px solid var(--sb-main-color);
}

.custom-marker .css-eg0mwd-MuiSlider-thumb:hover,
.custom-marker .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
  box-shadow: none;
}

.custom-marker .MuiSlider-thumb {
  box-sizing: content-box;
  border-radius: 100%;
  border: 8px solid var(--sb-main-color);
  background: #edeef0;
  width: 20px;
  height: 20px;
}

.custom-marker .MuiSlider-rail {
  border-radius: 5px;
  background: #d9d9d9;
  height: 8px;
}

.custom-marker .MuiSlider-track {
  background: var(--sb-main-color);
  border-color: var(--sb-main-color);
}

.divider {
  height: 1px;
  background: rgba(94, 94, 94, 0.17);
  margin: 0.5rem 0;
}

.league-content-tab li button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.league-content-tab ul li {
  border-radius: 18px;
}

.prize,
.rule {
}

.prize table,
.rule table {
  width: 100% !important;
}

.prize ol,
.rule ol {
  display: inline-block;
  list-style-type: decimal;
  padding: 0 0 0 10px;
}

.prize ol > ::marker,
.rule ol > ::marker {
  font-weight: bold;
}

.prize ol ol,
.rule ol ol {
  list-style-type: lower-alpha;
  float: left;
}

.prize ol ol > ::marker,
.rule ol ol > ::marker {
  font-weight: normal;
}

.prize ol ul,
.rule ol ul {
  list-style-type: "-  ";
  padding: 0 0 0 10px;
}

.prize ol ul ul,
.rule ol ul ul {
  list-style: "+  ";
  padding: 0 0 0 10px;
}

.prize table tr td {
  border-bottom: 0.1px solid hsl(172, 85%, 38%, 0.3);
  padding-top: 6px;
  padding-bottom: 6px;
}

.rule ol {
  width: 100%;
}
.rule table tr td {
  border-bottom: 0.1px solid hsl(172, 85%, 38%, 0.3);
  padding-top: 6px;
  padding-bottom: 6px;
}

.slider-progress .custom-marker .MuiSlider-thumb {
  border: none;
  margin-left: 6px;
  background: transparent;
  box-shadow: none !important;
}
.slider-progress .custom-marker .MuiSlider-thumb:before {
  display: none;
}
.slider-progress .custom-marker .MuiSlider-valueLabel {
  padding: 0 3px;
  margin-left: -2px;
  top: -5px;
}
.slider-progress .custom-marker .MuiSlider-rail {
  height: 8px;
}
.slider-progress .custom-marker .MuiSlider-track {
  height: 8px;
}
.slider-progress.not-claimed > .card {
  background: #f9c6c6;
}

.slider-progress.not-claimed > .card .label-text,
.slider-progress.not-claimed .custom-marker .MuiSlider-track {
  background: #ef7e7f;
  border-color: #ef7e7f;
}
.slider-progress.not-claimed
  .custom-marker
  .MuiSlider-mark.MuiSlider-markActive {
  border-color: #ef7e7f;
}

.slider-progress.running-not-claimed > .card {
  background: #ffecb7;
}

.slider-progress.running-not-claimed > .card .label-text,
.slider-progress.running-not-claimed .custom-marker .MuiSlider-track {
  background: #e4bb78;
  border-color: #e4bb78;
}
.slider-progress.running-not-claimed
  .custom-marker
  .MuiSlider-mark.MuiSlider-markActive {
  border-color: #e4bb78;
}

.slider-progress.waiting > .card {
  background: #777b84;
}

.slider-progress.waiting > .card .label-text {
  background: #5a6169;
}

.slider-progress.waiting .custom-marker .MuiSlider-mark.MuiSlider-markActive {
  border-color: #b0b4ba;
}

.slider-progress .text-max-matches {
  color: #777b84;
}

.slider-progress.waiting .text-max-matches {
  color: #b0b4ba;
}

.slider-progress.claimed > .card {
  background: #adf0dd;
}

.slider-progress.claimed > .card .label-text {
  background: #0eb39e;
}

.slider-progress.claimed .custom-marker .MuiSlider-mark.MuiSlider-markActive {
  border-color: #0eb39e;
}

.slider-progress .MuiSlider-root {
  margin-top: 20px;
  margin-bottom: 0;
}
.slider-progress .custom-marker .MuiSlider-markLabel {
  top: -10px;
  margin-left: 5px;
  font-family: Exo;
  font-size: 11px;
  color: #777b84;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.raw-css ul {
  list-style: disc;
  padding-left: 20px;
}

.raw-css ol {
  list-style: decimal;
  padding-left: 20px;
}

.react-modal-sheet-backdrop {
  /* custom styles */
  background: rgba(255, 255, 255, 0.2)!important;
}
.react-modal-sheet-container {
  /* custom styles */
  background: var(--sb-theme-color)!important;
  color: var(--dark-neutral-gray-12);
}
.react-modal-sheet-header {
  /* custom styles */
}
.react-modal-sheet-drag-indicator {
  /* custom styles */
}
.react-modal-sheet-content {
  /* custom styles */
  background: var(--base-bg-color)!important;
}

.sheet-container-scroller {
  max-height: calc(60vh - 120px);
}